<template>
  <v-container fluid> 
  </v-container>
</template>

<script>
export default {
  name: "Close",
  components: {},
  data: () => ({}),
  mounted() {
    this.$store.commit("deleteToken");
    
    this.$router.push("/session");
  },
  methods: {},
};
</script>
